import './App.css';
import giphy from './giphy.gif'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={giphy} alt={giphy}/>
        </header>
    </div>
  );
}

export default App;
